<template>
  <div>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="null"
      :subtitle="null"
      step-size="sm"
      @on-complete="onComplete"
    >
      <tab-content
        title="Personal details"
        icon="ti-user"
      >
        <vs-row>
          <vs-col
            vs-lg="6"
            vs-xs="12"
            vs-sm="6"
          >
            <vs-row class="mt-4">
              <vs-col
                vs-lg="3"
                vs-xs="12"
                vs-sm="4"
                class="d-flex align-items-center justify-content-start justify-content-md-end"
              >
                <label class="vs-input--label mb-0">First Name :</label>
              </vs-col>
              <vs-col
                vs-lg="9"
                vs-xs="12"
                vs-sm="8"
              >
                <vs-input
                  v-model="project1"
                  class="inputx w-100"
                  placeholder="First Name Here"
                />
              </vs-col>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col
                vs-lg="3"
                vs-xs="12"
                vs-sm="4"
                class="d-flex align-items-center justify-content-start justify-content-md-end"
              >
                <label class="vs-input--label mb-0">Last Name :</label>
              </vs-col>
              <vs-col
                vs-lg="9"
                vs-xs="12"
                vs-sm="8"
              >
                <vs-input
                  v-model="project2"
                  class="inputx w-100"
                  placeholder="Last Name Here"
                />
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col
            vs-lg="6"
            vs-xs="12"
            vs-sm="6"
          >
            <vs-row class="mt-4">
              <vs-col
                vs-lg="3"
                vs-xs="12"
                vs-sm="4"
                class="d-flex align-items-center justify-content-start justify-content-md-end"
              >
                <label class="vs-input--label mb-0">Email :</label>
              </vs-col>
              <vs-col
                vs-lg="9"
                vs-xs="12"
                vs-sm="8"
              >
                <vs-input
                  v-model="project3"
                  type="email"
                  class="inputx w-100"
                  placeholder="Email Here"
                />
              </vs-col>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col
                vs-lg="3"
                vs-xs="12"
                vs-sm="4"
                class="d-flex align-items-center justify-content-start justify-content-md-end"
              >
                <label class="vs-input--label mb-0">Contact No :</label>
              </vs-col>
              <vs-col
                vs-lg="9"
                vs-xs="12"
                vs-sm="8"
              >
                <vs-input
                  v-model="project4"
                  type="tel"
                  class="inputx w-100"
                  placeholder="Contact No Here"
                />
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
      </tab-content>
      <tab-content
        title="Reuirements"
        icon="ti-settings"
      >
        <vs-row>
          <vs-col
            vs-lg="6"
            vs-xs="12"
            vs-sm="6"
          >
            <vs-row class="mt-4">
              <vs-col
                vs-lg="3"
                vs-xs="12"
                vs-sm="4"
                class="d-flex align-items-center justify-content-start justify-content-md-end"
              >
                <label class="vs-input--label mb-0">Company :</label>
              </vs-col>
              <vs-col
                vs-lg="9"
                vs-xs="12"
                vs-sm="8"
              >
                <vs-input
                  v-model="project5"
                  class="inputx w-100"
                  placeholder="Company Name Here"
                />
              </vs-col>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col
                vs-lg="3"
                vs-xs="12"
                vs-sm="4"
                class="d-flex align-items-center justify-content-start justify-content-md-end"
              >
                <label class="vs-input--label mb-0">Interested In :</label>
              </vs-col>
              <vs-col
                vs-lg="9"
                vs-xs="12"
                vs-sm="8"
              >
                <vs-select
                  v-model="projectselect6"
                  class="w-100"
                >
                  <vs-select-item
                    v-for="(item,index) in projectoptions6"
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                  />
                </vs-select>
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col
            vs-lg="6"
            vs-xs="12"
            vs-sm="6"
          >
            <vs-row class="mt-4">
              <vs-col
                vs-lg="3"
                vs-xs="12"
                vs-sm="4"
                class="d-flex align-items-center justify-content-start justify-content-md-end"
              >
                <label class="vs-input--label mb-0">Budget :</label>
              </vs-col>
              <vs-col
                vs-lg="9"
                vs-xs="12"
                vs-sm="8"
              >
                <vs-select
                  v-model="projectselect7"
                  class="w-100"
                >
                  <vs-select-item
                    v-for="(item,index) in projectoptions7"
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                  />
                </vs-select>
              </vs-col>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col
                vs-lg="3"
                vs-xs="12"
                vs-sm="4"
                class="d-flex align-items-center justify-content-start justify-content-md-end"
              >
                <label class="vs-input--label mb-0">About Project :</label>
              </vs-col>
              <vs-col
                vs-lg="9"
                vs-xs="12"
                vs-sm="8"
              >
                <vs-input
                  v-model="project8"
                  class="inputx w-100"
                  placeholder="About Project Here"
                />
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
      </tab-content>
      <tab-content
        title="Last step"
        icon="ti-check"
      >
        <vs-row class="mt-4">
          <vs-col
            vs-lg="2"
            vs-xs="12"
            vs-sm="4"
            class
          >
            <label class="vs-input--label mb-0">Total Experience :</label>
          </vs-col>
          <vs-col
            vs-lg="9"
            vs-xs="12"
            vs-sm="8"
          >
            <div class="d-block float-left mt-1">
              <vs-radio
                v-model="event6"
                vs-name="event6"
                vs-value="1"
              >
                1 year
              </vs-radio>
              <vs-radio
                v-model="event6"
                vs-name="event6"
                vs-value="2"
              >
                2 year
              </vs-radio>
              <vs-radio
                v-model="event6"
                vs-name="event6"
                vs-value="3"
              >
                3 year
              </vs-radio>
              <vs-radio
                v-model="event6"
                vs-name="event6"
                vs-value="4"
              >
                4 year
              </vs-radio>
              <vs-radio
                v-model="event6"
                vs-name="event6"
                vs-value="5"
              >
                5 year
              </vs-radio>
            </div>
          </vs-col>
        </vs-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

export default {
  name: 'FormWizardIcon',
  components: {
    FormWizard,
    TabContent
  },
  data: () => ({
    title: 'FormWizardIcon',
    project1: '',
    project2: '',
    project3: '',
    project4: '',
    project5: '',
    projectselect6: 0,
    projectoptions6: [
      { text: 'Choose Your option', value: 0 },
      { text: 'Designing', value: 1 },
      { text: 'Development', value: 2 }
    ],
    projectselect7: 0,
    projectoptions7: [
      { text: 'Choose Your option', value: 0 },
      { text: 'Less than $5000', value: 1 },
      { text: '$5000 to $10000', value: 2 }
    ],
    project8: '',
    event6: ''
  }),
  methods: {
    onComplete: function() {
      alert('Yay. Done!');
    }
  }
};
</script>