<template>
  <div class="text-center">
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="null"
      :subtitle="null"
      @on-complete="onComplete"
    >
      <tab-content
        title="Personal details"
        icon="ti-user"
      >
        <span class="font-weight-bold">My first tab content</span>
      </tab-content>
      <tab-content
        title="Reuirements"
        icon="ti-settings"
      >
        <span class="font-weight-bold">My second tab content</span>
      </tab-content>
      <tab-content
        title="Last step"
        icon="ti-check"
      >
        <span class="font-weight-bold">Yuhuuu! This seems pretty damn simple</span>
      </tab-content>
      <vs-button
        slot="prev"
        color="dark"
      >
        <i class="mdi mdi-chevron-left mr-1" />
        Back
      </vs-button>
      <vs-button
        slot="next"
        color="primary"
      >
        Next
        <i class="mdi mdi-chevron-right ml-1" />
      </vs-button>
      <vs-button
        slot="finish"
        color="success"
      >
        Finish
        <i class="mdi mdi-bookmark-check ml-1" />
      </vs-button>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

export default {
  name: 'FormWizardButtonSlot',
  components: {
    FormWizard,
    TabContent
  },
  data: () => ({
    title: 'FormWizardButtonSlot'
  }),
  methods: {
    onComplete: function() {
      alert('Yay. Done!');
    }
  }
};
</script>