<template>
  <div class="text-center">
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="null"
      :subtitle="null"
      :start-index="1"
      @on-complete="onComplete"
    >
      <tab-content
        title="Personal details"
        icon="ti-user"
      >
        <span class="font-weight-bold">My first tab content</span>
      </tab-content>
      <tab-content
        title="Reuirements"
        icon="ti-settings"
      >
        <span class="font-weight-bold">My second tab content</span>
      </tab-content>
      <tab-content
        title="Last step"
        icon="ti-check"
      >
        <span class="font-weight-bold">Yuhuuu! This seems pretty damn simple</span>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

export default {
  name: 'FormWizardIndex',
  components: {
    FormWizard,
    TabContent
  },
  data: () => ({
    title: 'FormWizardIndex'
  }),
  methods: {
    onComplete: function() {
      alert('Yay. Done!');
    }
  }
};
</script>